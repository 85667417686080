import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider'

import DOMPurify from 'dompurify';

import { retrieveFaqs } from '../../actions/faqAction'

const useStyles = makeStyles(() => ({
    faqTitle: {
        cursor: 'pointer', 
        color: '#174C81', 
        fontSize: '20px', 
        fontWeight: 600, 
        marginBottom: '20px'
    },
    faqQuestion: {
        cursor: 'pointer', 
        color: '#6d9bc8', 
        lineHeight: '30px', 
        fontWeight: 600, 
        overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        padding: '5px 0 5px'
    },
    faqAnswer:{
        color: '#707070', 
        lineHeight: '30px', 
        overflowWrap: 'anywhere',
        wordBreak: 'break-word', 
        padding: '5px 0 5px'
    },
}));

export default function Faq(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openStateTitle, setOpenStateTitle] = useState(false);
    const [openState, setOpenState] = useState(true);
    const [faqs, setFaqs] = useState([]);

    const toggleTitleCollapse = () => {
        setOpenStateTitle(prevState => !prevState);
    };


    const toggleCollapse = (key) => {
        setOpenState((prevState) => ({
        ...prevState,
        [key]: !prevState[key]
        }));
    };


    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const fetchedFaqs = await dispatch(retrieveFaqs(props.screen))
                setFaqs(fetchedFaqs.items)
            }
            catch (e) {
                console.error(e)
            }
        }
        fetchFaqs()
    }, [dispatch]);

    const customPadding = {
        paddingLeft: props.paddingLeft ? props.paddingLeft : '5px',
        paddingRight: props.paddingRight ? props.paddingLeft : '5px',
    }

    return(
        <>
            <Divider style={{ marginBottom: "20px"}}/>
            <Typography onClick={() => toggleTitleCollapse()} variant="h2" component="h2" className={classes.faqTitle} style={customPadding}>
                常見問題
                {<ExpandMoreIcon 
                    style={{ verticalAlign: "middle",
                    transform: openStateTitle ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} 
                />}
            </Typography>

            <Collapse in={openStateTitle}>
                {faqs.map((item,index) => (
                    <>
                        <div style={{ marginBottom: '20px'}}>
                            <Divider style={{ marginBottom: "20px"}}/>
                            <Typography onClick={() => toggleCollapse(index)} className={classes.faqQuestion} style={customPadding}>
                                {openState[index] ? item.question : item.question}
                                {<ExpandMoreIcon 
                                    style={{ verticalAlign: "middle",
                                    transform: openState[index] ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} 
                                />}
                            </Typography>
                            
                            <Collapse in={openState[index]}>
                                <Typography className={classes.faqAnswer} style={customPadding}>
                                    {<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }} />}
                                </Typography>
                            </Collapse>
                        </div>
                    </>
                ))}
            </Collapse>
        </>
    )
}

Faq.propTypes = {
    screen: PropTypes.string,
    paddingLeft: PropTypes.string,
    paddingRight: PropTypes.string,
}