import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import * as Icon from '@material-ui/icons';
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
//import { useDispatch, useSelector } from 'react-redux'
//import { hasLoggedIn } from '../../actions/authAction'
import { INSTAGRAM_LINK, FB_LINK, YOUTUBE_LINK, KOFI_LINK, SUPPORT_EMAIL } from '../../constants'
import classNames from 'classnames/bind';
import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import kofiIcon from '../../../assets/shared/kofiIcon.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    grid: {
        paddingLeft: 60,
        [theme.breakpoints.down('sm')]: {
            padding: '0 30px',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title: {
        fontWeight: 600,
        color: '#000',
        fontSize: '1rem',
        margin: '20px 0'
    },
    content: {
        fontWeight: 600,
        //color: '#174C81',
        color: '#6293C3',
        fontSize: '1rem',
        margin: '5px 0'
    },
    iconBtn: {
        border: '1px solid',
        marginRight: 10,
        padding: '8px',
    },
    icon: {
        fontSize: 30,
    },
    youtubeIcon: {
        color: '#ff0000',
    },
    margin: {
        margin: '20px 0',
    },
    marginBottom: {
        marginBottom: 30,
    },
    kofiGrid: {
        marginTop: 30,
        marginBottom: 30,
    },
    kofiButton: {
        backgroundColor: '#45dec6',
    },
    copyright: {
        color: '#555',
        fontSize: '1rem',
        textAlign: 'center',
        paddingTop: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px',
        },
    },
    copyright2: {
        color: '#555',
        fontSize: '1rem',
        textAlign: 'center',
        paddingBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '60px',
        },
    },
    
}));

export default function Footer() {
    const classes = useStyles();

    //Footer
    const MenuLink = props => (
        <Link className={classes.content} component={NavLink} display="block" variant="body2" {...props} />
    );
    //const dispatch = useDispatch();
    //const currentUser = useSelector(state => state.auth.user);

    return (
        <Container>
            <Grid container className={classes.root}>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Typography className={classes.title}>連結</Typography>
                    <MenuLink to='/faq'>常見問題</MenuLink>
                    <MenuLink to='/terms/discount'>申請學生優惠</MenuLink>
                    <MenuLink to='/service-agreement'>用戶服務條款</MenuLink>
                    <MenuLink to='/privacy-policy'>私隱政策</MenuLink>
                    {/*<MenuLink to='/search'>搜尋學識</MenuLink>
                    <MenuLink to='/blog'>閱讀學識</MenuLink>
                    <MenuLink to='/become-teacher'>傳授學識</MenuLink>
                    <MenuLink to='/about'>關於學識</MenuLink>
                    <MenuLink to='/faq'>常見問題</MenuLink>
                    <MenuLink to={
                        dispatch(hasLoggedIn) && currentUser ? `/user/${currentUser.id}` : '/login'
                    }>{dispatch(hasLoggedIn) && currentUser ? '我的學識' : '登入/註冊'}</MenuLink>*/}
                </Grid>
                <Grid item xs={12} md={4} className={classes.grid}>
                    <Typography className={classes.title}>聯絡我們</Typography>
                    {/*<Typography className={`${classes.content} ${classes.marginBottom}`}>電郵：{SUPPORT_EMAIL}</Typography>*/}
                    <Button  className={`${classes.content} ${classes.marginBottom}`} endIcon={<SendIcon/>}
                    href={`mailto:${SUPPORT_EMAIL}`}>電郵：{SUPPORT_EMAIL}</Button>
                </Grid>
                <Grid item xs={12} md={4} direction="column" className={classes.grid}>
                    <Typography className={classes.title}>關注我們</Typography>
                    <Grid item>
                        <IconButton className={classes.iconBtn} color="primary" href={FB_LINK} target="_blank">
                            <Icon.Facebook className={classes.icon} />
                        </IconButton>
                        <IconButton className={classes.iconBtn} color="secondary" href={INSTAGRAM_LINK} target="_blank">
                            <Icon.Instagram className={classes.icon} />
                        </IconButton>
                        <IconButton className={classNames(classes.iconBtn, classes.youtubeIcon)} color="inherit" href={YOUTUBE_LINK} target="_blank">
                            <Icon.YouTube className={classes.icon} />
                        </IconButton>
                    </Grid>
                    <Grid item className={classes.kofiGrid}>
                        <Button variant='contained' className={classes.kofiButton} href={KOFI_LINK} target="_blank">
                            <img src={kofiIcon} height={15} alt={"kofiIcon"}/>
                               學識會員
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.copyright}>Copyright © {new Date().getFullYear()} hok6.com </Typography>
                    <Typography className={classes.copyright2}>版權所有，不得轉載</Typography>
                </Grid>
            </Grid>
        </Container>
    );
}