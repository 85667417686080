import React from 'react';
import PropTypes from "prop-types";
import _ from '../../lodash-wrapper';
import { MetaTags as MT } from 'react-meta-tags';

export default function MetaTags(props) {
    const {
        id,
        titles,
        description,
        image,
        profileName,
        prerenderReady,
        statusCode,
    } = props;

    const previewImage = image || `${process.env.PUBLIC_URL}/img/banner_socrates.jpg`;
    const titleDisplayed = _.join(_.filter([...titles, '學識 Hok6 | 廣東話網上教育平台'], v => !_.isNil(v)),' | ');
    const descriptionDisplayed = description || '學識 Hok6，專為香港人及全球廣東話使用者而設的網上教育平台，提供語言、文學、哲學、歷史、科技等優質線上課程及講座，讓你隨時隨地以粵語學習新知識。';

    return (
        <MT id={`meta-tags-${id}`}>
            <title id='title'>{titleDisplayed}</title>
            <meta id='meta-description' name="description" content={descriptionDisplayed} />

            {/* Open Graph Meta Tags */}
            <meta id='meta-title'           property="og:title"             content={titleDisplayed} />
            <meta id='meta-description-og'  property="og:description"       content={descriptionDisplayed} />
            <meta id='meta-image'           property="og:image"             content={previewImage} />
            <meta id='meta-site-name'       property="og:site_name"         content="學識 Hok6 | 廣東話網上教育平台" />
            <meta id='meta-status-code'     property="prerender:statusCode" content={statusCode} />
            {profileName && 
                <>
                    <meta id='meta-type-profile' property="og:type" content="profile" />
                    <meta id='meta-username' property="profile:username" content={profileName} />
                </>
            }

            {/* Twitter SEO Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={titleDisplayed} />
            <meta name="twitter:description" content={descriptionDisplayed} />
            <meta name="twitter:image" content={previewImage} />

            {profileName && (
                <>
                    <meta id='meta-username' property="profile:username" content={profileName} />
                </>
            )}
            <meta id='meta-prerender-ready' property="prerenderReady" content={prerenderReady?"prerenderReady":"prerenderNotReady"} />
        </MT>
    );
}

MetaTags.propTypes = {
    id: PropTypes.string.isRequired,
    titles: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    image: PropTypes.string,
    profileName: PropTypes.string,
    prerenderReady: PropTypes.bool,
    statusCode: PropTypes.number,
    // statusCode - this is not the status code a human user receives in browser
    // this is referred by the prerendering service and will only be received by crawlers
};

MetaTags.defaultProps = {
    titles: [],
    prerenderReady: true,
    statusCode: 200,
}