import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import PropType from 'prop-types';
import _ from '../../lodash-wrapper';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import LoadingBackdrop from '../shared/loadingBackdrop'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DOMPurify from 'dompurify';

import MetaTags from '../shared/meta';
import CourseAdaptiveContainer from '../course/courseAdaptiveContainer';
import PageNotExistScreen from '../../components/pageNotFoundScreen';
import { PaymentCourse } from '../../models/course';
import { CourseMedium } from '../../models/enums';
import { Hok6Error, NoAccessError, NotFoundError } from '../../models/error';
import {getCourseEnrolledUrl, datetimeFormat, getPriceText, isGteToday} from '../../utils';
import {DATETIME_DISPLAY_FORMAT} from '../../constants';
import { initiateCourseRegistration } from '../../actions/courseRegistrationAction';
import { AuthenticatedRouteChildPropTypes } from '../auth/authenticatedRoute';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import ReadAndAgreeTermsAndConditionText from "../shared/readAndAgreeTermsAndConditionsText";
//import {Checkbox} from "@material-ui/core";
import Faq from '../shared/faq'

const useStyles = makeStyles(theme => ({
    alert: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    divider: {
        borderBottom: "2px solid lightgray",
        width: "100%",
        marginBottom: '20px',
    },
    courseDetailsContainer: {
        flexGrow: 1,
        marginTop: '20px',
        paddingLeft: 0,
        paddingRight: 0,
    },
    courseSectionContainer: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: '20px',
    },
    sectionTitle: {
        color: '#000000',
        ...theme.text.sectionTitle,
        fontWeight: 600,
        marginBottom: '10px',
    },
    amount: {
        color: '#000000',
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: '10px',
    },
    contentText: {
        ...theme.text.contentText,
        color: '#707070',
        lineHeight: '30px',
        overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        marginRight: '0px',
    },
    sessionTimeItem: {
        paddingInlineStart: '25px',
    },
    checkoutButtonContainer: {
        textAlign: 'center',
        marginTop: '30px',
        marginBottom: '30px',
    },
    disclaimer: {
        ...theme.text.contentText,
        marginBottom: '20px',
    },
    disclaimerItem: {
        paddingInlineStart: '25px',
    },
    disclaimerItemHighlight: {
        paddingInlineStart: '25px',
        fontWeight: 600,
    },
    disclaimerItemHighlight2: {
        paddingInlineStart: '0px',
        fontWeight: 600,
    },
    lateEnrollmentDisclaimer: {
        ...theme.text.sectionTitle,
        fontWeight: 600,
    },
    largeButton: {
        padding: '12px 24px', // Adjust the padding as needed
        fontSize: '1.4rem', // Adjust the font size as needed
    },
}));

const Divider = () => {
    const classes = useStyles(useTheme());
    return (
        <div className={classes.divider}/>
    );
}

const renderTextContentSection = (classes, content) => {
    return (
        <Container className={classes.contentContainer}>
            <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} 
                className={classes.contentText}
            />
        </Container>
    );
}

export const PaymentCourseDetails = props => {
    const classes = useStyles(useTheme());
    const {course} = props;
    return (
        <Container className={classes.courseDetailsContainer}>
            <Typography className={classes.disclaimer}>付款前請確認下列相關資訊：</Typography>
            <Container className={classes.courseSectionContainer}>
                <Typography className={classes.sectionTitle}>
                    形式：{CourseMedium[course.medium].displayName}
                </Typography>
                <Typography 
                    className={classes.contentText}
                >
                    {renderTextContentSection(classes, course.teachingPattern)}
                </Typography>
            </Container>
            {course.startTime &&
            <Container className={classes.courseSectionContainer}>
                <Typography className={classes.sectionTitle}>
                    時間
                </Typography>
                <Typography>{datetimeFormat(course.startTime, DATETIME_DISPLAY_FORMAT )} 開始</Typography>
                <ul className={classes.contentText}>
                    {course.sessions.map(
                        s => <li key={s.id} className={classes.sessionTimeItem}>
                            {datetimeFormat(s.time,DATETIME_DISPLAY_FORMAT)} ({s.durationMinute} 分鐘)
                        </li>
                    )}
                </ul>
            </Container>
            }
            <Container className={classes.courseSectionContainer}>
                {course.startTime && isGteToday(course.startTime) && (
                    <Typography className={classes.lateEnrollmentDisclaimer} style={{whiteSpace: 'pre-line'}}>
                        請注意：你所報讀的課程經已開始，如果你現在報讀，將會錯過部分課堂。
                    </Typography>
                )}
            </Container>
        </Container>
    );
};
PaymentCourseDetails.propTypes = {
    course: PropType.instanceOf(PaymentCourse),
}

export function CourseEnrollmentContainer(props) {
    const { course, checkoutUrl, paymentSystem } = props;
    const classes = useStyles(useTheme());

    {/*const [checkedReadAndAgreeTermsAndConditions, setCheckedReadAndAgreeTermsAndConditions] = useState(false);

    const handleReadAndAgreeTermsAndConditionsChange = async (e) => {
        setCheckedReadAndAgreeTermsAndConditions(e.target.checked);
    };*/}
    return (
        <>
          <MetaTags id='course-enrollment' titles={['報讀課程', course && course.title]} />
            
          <main>
            <CourseAdaptiveContainer
                target={course}
                teachers={course.teachers}
                otherTeachers={course.otherTeachers}
                rightSectionEnabled={false}
                targetRatingEnabled={false}
                prerenderReady={course !== null}
            >
                <PaymentCourseDetails course={course}/>
                <Typography className={classes.sectionTitle}>注意事項</Typography>
                { paymentSystem === 'MANUAL' && <Alert className={classes.alert} severity='info'>
                    學識職員已收到閣下的報名申請，並將會在確認款項後更新報名狀態。
                </Alert>}
                { checkoutUrl &&
                    <Grid container>
                        <Grid item xs={12}>
                            {/*<FormControlLabel
                                control={<Checkbox
                                    checked={checkedReadAndAgreeTermsAndConditions}
                                    onChange={handleReadAndAgreeTermsAndConditionsChange}
                                    name='readAndAgreeTermsAndConditions'/>}
                                label={<ReadAndAgreeTermsAndConditionText/>}
                                name={'readAndAgreeTermsAndConditions'}
                            />*/}
                            <Container className={classes.courseSectionContainer}>
                                <ReadAndAgreeTermsAndConditionText />
                            </Container>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <Container className={classes.courseSectionContainer}>
                                <Typography className={classes.amount}>
                                    價錢：{getPriceText(course.priceCurrency, course.priceAmount)} 
                                </Typography>
                                <Typography className={classes.disclaimerItemHighlight2}>
                                按下「前往付款」，會去到 Stripe 頁面，可使用優惠碼，以信用卡、Apple Pay 或 Google Pay 等方式付費。香港用戶亦可 FPS（support@hok6.com）或銀行轉帳（DBS Bank, 001609376, Hok6 Limited），截圖通知學識（FB/IG message 或 email）核對。如有需要，亦可聯絡我們用其他方法付費。
                                </Typography>
                            </Container>
                        </Grid>
                        <Grid item xs={12}>
                            <Container className={classes.checkoutButtonContainer}>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    href={checkoutUrl}
                                    //disabled={!checkedReadAndAgreeTermsAndConditions}
                                    className={classes.largeButton}
                                >前往付款</Button>
                            </Container>
                        </Grid>
                        <Grid item xs={12}>
                            <Faq screen='payment_screen' />
                        </Grid>
                    </Grid>
                }
            </CourseAdaptiveContainer>
          </main>
        </>
    );
}

CourseEnrollmentContainer.propTypes = {
    course: PropType.instanceOf(PaymentCourse).isRequired,
    checkoutUrl: PropType.string,
    paymentSystem: PropType.string,
};


const CourseEnrollmentScreen = ({ onNoAccess }) => {
    const dispatch = useDispatch();

    const { id } = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [navState, setNavState] = useState(null);
    const [registrationResponse, setRegistrationResponse] = useState(null);

    useEffect(() => {
        async function initRegistration() {
            try {
                const res = await dispatch(initiateCourseRegistration(id));
                
                switch (res.status) {
                    case 'PENDING_PAYMENT':
                        setRegistrationResponse(res)
                        return;
                    case 'CONFIRMED':
                        setNavState({
                            pathname: getCourseEnrolledUrl(id),
                            state: {
                                savePaymentMethod: false,
                                ...res.toObject(),
                            }
                        });
                        return;
                    case 'CANCELLED_BY_STUDENT':
                        setError(new Hok6Error('閣下已退出此課程'));
                        break;
                    default:
                        setError(new Hok6Error('閣下早前已報讀同一課程'));
                }
            } catch (e) {
                if (e instanceof NoAccessError) {
                    onNoAccess();
                } else {
                    setError(e);
                }
            } finally {
                setLoading(false);
            }
        }

        initRegistration();
        window.scrollTo(0, 0);

    }, [id, dispatch])

    if (error && error instanceof NotFoundError) {
        return (
            <PageNotExistScreen />
        );
    }

    return (
        <>
            <LoadingBackdrop open={loading} />
            {error && <Alert severity='error'>{error.message}</Alert>}
            {navState && <Redirect to={navState} />}
            {registrationResponse && registrationResponse.status === "PENDING_PAYMENT" && (
                <CourseEnrollmentContainer
                    course={registrationResponse.course}
                    checkoutUrl={registrationResponse.checkoutUrl}
                    paymentSystem={registrationResponse.paymentSystem}
                />
            )}
        </>
    );
}

CourseEnrollmentScreen.propTypes = {
    ...AuthenticatedRouteChildPropTypes,
};

export default CourseEnrollmentScreen;
